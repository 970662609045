import * as XLSX from "xlsx/xlsx.mjs";

function formatData(data) {
  return data.map((obj) => {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key.endsWith("_id") || key.endsWith("_at")) {
        continue;
      }
      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        const flattenedObj = flattenObject(value);
        newObj[key] = flattenedObj;
      } else if (Array.isArray(value)) {
        newObj[key] = value.map((e) => flattenObject(e)).join("\n");
      } else {
        newObj[key] = value;
      }
    }

    return newObj;
  });
}

// Helper function to flatten an object
function flattenObject(obj) {
  if (Object.prototype.hasOwnProperty.call(obj, "name")) return obj.name;
  else if (Object.prototype.hasOwnProperty.call(obj, "value")) return obj.value;
  else if (Object.prototype.hasOwnProperty.call(obj, "niveau"))
    return obj.niveau;
  else if (Object.prototype.hasOwnProperty.call(obj, "description"))
    return obj.description;
  else return "_________";
}

export function jsonToExcel(jsonData, filename) {
  const worksheet = XLSX.utils.json_to_sheet(formatData(jsonData));
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}
