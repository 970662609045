<template>
  <template v-if="can(module, action, roles)">
    <slot />
  </template>
</template>
<script setup>
import { can } from "@/core/config/MainMenuConfig";
import { onMounted, ref } from "vue";

const props = defineProps({
  module: {
    type: String,
    default: "",
  },
  action: {
    type: String,
    default: "",
  },
  roles: {
    type: Array,
    default: () => ["Superadmin"],
  },
});

const roles = ref(props.roles);

onMounted(() => {
  roles.value = ["Superadmin", ...props.roles];
});
</script>
